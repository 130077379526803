import {i18n} from "../i18n/i18n"
import {AuthPerson} from "../api/authAPI"
import {DefaultPortalSection} from "../features/PortalSectionEffector/DefaultPortalSection"

export enum Portal {
    Auth = "AUTH",
    Home = "HOME",
    Users = "USERS",
    Admin = "ADMIN",
    WebPlayer = "WEB_PLAYER",
    Screens = "SCREENS",
    Content = "CONTENT",
    Repository = "REPOSITORY",
    Student = "STUDENT",
    Messages = "MESSAGES",
    Creative = "CREATIVE",
    Menu = "MENU",
    News = "NEWS",
    Reports = "REPORTS",
    Provider = "PROVIDER",
    Settings = "SETTINGS",
}

export type PortalsPanelItems = {[p in keyof AuthPerson["roleAuthorities"]]?: PortalsPanelItem[]}

export enum PortalsPanelItem {
    Home = "HOME",
    Users = "USERS",
    Admin = "ADMIN",
    Screens = "SCREENS",
    Content = "CONTENT",
    Repository = "REPOSITORY",
    Student = "STUDENT",
    Messages = "MESSAGES",
    Menu = "MENU",
    News = "NEWS",
    Reports = "REPORTS",
    Provider = "PROVIDER",
    Alerts = "ALERT",
    Settings = "SETTINGS",
}

export const ViewPortal = {
    [Portal.Auth]: "auth portal",
    [Portal.Home]: "home portal",
    [Portal.Users]: "user portal",
    [Portal.Admin]: "admin portal",
    [Portal.Screens]: "screen portal",
    [Portal.Content]: "content portal",
    [Portal.Repository]: "library portal",
    [Portal.Student]: "student portal",
    [Portal.Messages]: "messages portal",
    [Portal.Creative]: "creative portal",
    [Portal.Menu]: "menu portal",
    [Portal.News]: "news portal",
    [Portal.Reports]: "reports portal",
    [Portal.Provider]: "provider portal",
    [Portal.Settings]: "settings portal",
    [Portal.WebPlayer]: "provider portal",
}

export const getViewPortal = () => ({
    [Portal.Auth]: i18n.t("common:portals_name.auth"),
    [Portal.Home]: i18n.t("common:portals_name.home"),
    [Portal.Users]: i18n.t("common:portals_name.users"),
    [Portal.Admin]: i18n.t("common:portals_name.admin"),
    [Portal.Screens]: i18n.t("common:portals_name.screens"),
    [Portal.Content]: i18n.t("common:portals_name.content"),
    [Portal.Repository]: i18n.t("common:portals_name.library"),
    [Portal.Student]: i18n.t("common:portals_name.student"),
    [Portal.Messages]: i18n.t("common:portals_name.messages"),
    [Portal.Creative]: i18n.t("common:portals_name.creative"),
    [Portal.Menu]: i18n.t("common:portals_name.menu"),
    [Portal.News]: i18n.t("common:portals_name.news"),
    [Portal.Reports]: i18n.t("common:portals_name.reports"),
    [Portal.Provider]: i18n.t("common:portals_name.provider"),
    [Portal.Settings]: i18n.t("common:portals_name.settings"),
})

export const PortalRoute: {[p in Portal]: string} = {
    [Portal.Auth]: "/auth",
    [Portal.Admin]: "/admin",
    [Portal.Users]: "/users",
    [Portal.Home]: "/home",
    [Portal.Screens]: "/screens",
    [Portal.Content]: "/content",
    [Portal.Repository]: "/repository",
    [Portal.Student]: "/student",
    [Portal.Messages]: "/messages",
    [Portal.Creative]: "/creative",
    [Portal.Menu]: "/menu",
    [Portal.News]: "/news",
    [Portal.Reports]: "/reports",
    [Portal.Provider]: "/provider",
    [Portal.Settings]: "/settings",
    [Portal.WebPlayer]: "/web_player",
}

export const getPortalsPanelItemLink = () => ({
    [PortalsPanelItem.Home]: PortalRoute[Portal.Home],
    [PortalsPanelItem.Users]: PortalRoute[Portal.Users],
    [PortalsPanelItem.Admin]: PortalRoute[Portal.Admin],
    [PortalsPanelItem.Screens]: PortalRoute[Portal.Screens],
    [PortalsPanelItem.Content]: PortalRoute[Portal.Content],
    [PortalsPanelItem.Repository]: PortalRoute[Portal.Repository],
    [PortalsPanelItem.Student]: PortalRoute[Portal.Student],
    [PortalsPanelItem.Messages]: PortalRoute[Portal.Messages],
    [PortalsPanelItem.Menu]: PortalRoute[Portal.Menu],
    [PortalsPanelItem.News]: PortalRoute[Portal.News],
    [PortalsPanelItem.Reports]: PortalRoute[Portal.Reports],
    [PortalsPanelItem.Provider]: PortalRoute[Portal.Provider],
    [PortalsPanelItem.Settings]: PortalRoute[Portal.Settings],
    [PortalsPanelItem.Alerts]:
        PortalRoute[Portal.Messages] + DefaultPortalSection.route + "/alerts",
})

export const Authorities = {
    admins: {
        master: "MASTER_ADMIN",
        support: "SUPPORT_ADMIN",
    },
    tools: {
        useLibrary: {
            canva: "TOOLS_USE_LIBRARY_CANVA",

            localContent: "TOOLS_USE_LIBRARY_LOCAL_CONTENT_VIEW",
            globalContent: "TOOLS_USE_LIBRARY_GLOBAL_CONTENT_VIEW",

            localThemes: "TOOLS_USE_LIBRARY_LOCAL_CONTENT_VIEW",
            globalThemes: "TOOLS_USE_LIBRARY_GLOBAL_CONTENT_VIEW",

            premium: "TOOLS_USE_LIBRARY_PREMIUM_ITEM_VIEW",

            aiContent: "TOOLS_USE_LIBRARY_AI",
        },
        alerts: {
            headerAlerts: "TOOLS_HEADER_ALERTS_VIEW",
        },
        creative: {
            ai_image: "TOOLS_CREATIVE_AI_IMAGE",
            ai_text: "TOOLS_CREATIVE_AI_TEXT",

            localThemes: "TOOLS_LOCAL_CREATIVE_THEMES",
            globalThemes: "TOOLS_GLOBAL_CREATIVE_THEMES",

            localSamples: "TOOLS_LOCAL_CREATIVE_SAMPLES",
            globalSamples: "TOOLS_GLOBAL_CREATIVE_SAMPLES",

            brands: "TOOLS_LOCAL_CREATIVE_BRANDS",

            useLibraryContent: "TOOLS_CREATIVE_USE_LIBRARY_CONTENT",
        },
    },
    screenPortal: {
        screens: {
            list: {
                view: "SCREENS_SCREEN_VIEW",
                changeStatus: "SCREENS_SCREEN_EDIT",
            },
            anyScreen: {
                view: "SCREENS_SCREEN_VIEW",
                preview: "SCREENS_SCREEN_VIEW",
                edit: "SCREENS_SCREEN_EDIT",
                manageAccess: "SCREENS_SCREEN_MANAGE_ACCESS",
            },
            regularScreen: {
                create: "SCREENS_SCREEN_CREATE",
                delete: "SCREENS_SCREEN_CREATE",
                editDevices: "SCREENS_SCREEN_EDIT_DEVICES",
            },
            lmsScreen: {
                create: "SCREENS_VIRTUAL_SCREEN_CREATE",
                delete: "SCREENS_VIRTUAL_SCREEN_CREATE",
            },
        },
        periods: {
            view: "SCREENS_PERIOD_VIEW",
            edit: "SCREENS_PERIOD_EDIT",
        },
        devices: {
            list: {
                view: "SCREENS_DEVICE_VIEW",
            },
            device: {
                edit: "SCREENS_DEVICE_EDIT",
                delete: "SCREENS_DEVICE_DELETE",
                statistics: "SCREENS_DEVICE_VIEW",
                preview: "SCREENS_DEVICE_PREVIEW",
            },
            logs: {
                view: "ADMIN_BUILDS_EDIT",
                edit: "ADMIN_BUILDS_EDIT",
            },
        },
        vacantDevices: {
            view: "SCREENS_VACANT_DEVICE_VIEW",
            edit: "SCREENS_VACANT_DEVICE_DELETE",
        },
    },
    contentPortal: {
        content: {
            landscape: {
                create: "CONTENT_COMMON_MAIN_CREATE",

                list: "CONTENT_MAIN_VIEW",
                preview: "CONTENT_MAIN_VIEW",
                edit: "CONTENT_MAIN_EDIT",
                delete: "CONTENT_MAIN_EDIT",
                assignToScreens: "CONTENT_MAIN_EDIT",
                changeOrder: "CONTENT_MAIN_CHANGE_ORDER",
            },
            side: {
                create: "CONTENT_COMMON_SIDE_CREATE",

                list: "CONTENT_SIDE_VIEW",
                preview: "CONTENT_SIDE_VIEW",
                edit: "CONTENT_SIDE_EDIT",
                delete: "CONTENT_SIDE_EDIT",
                assignToScreens: "CONTENT_SIDE_EDIT",
                changeOrder: "CONTENT_SIDE_CHANGE_ORDER",
            },
            feed: {
                create: "CONTENT_COMMON_FEED_CREATE",
                bonuslyEdit: "CONTENT_BONUSLY_FEED_CREATE",

                list: "CONTENT_FEED_VIEW",
                preview: "CONTENT_FEED_VIEW",
                edit: "CONTENT_FEED_EDIT",
                delete: "CONTENT_FEED_EDIT",
                assignToScreens: "CONTENT_FEED_EDIT",
                changeOrder: "CONTENT_FEED_CHANGE_ORDER",
            },
            portrait: {
                create: "CONTENT_COMMON_PORTRAIT_CREATE",

                list: "CONTENT_PORTRAIT_VIEW",
                preview: "CONTENT_PORTRAIT_VIEW",
                edit: "CONTENT_PORTRAIT_EDIT",
                delete: "CONTENT_PORTRAIT_EDIT",
                assignToScreens: "CONTENT_PORTRAIT_EDIT",
                changeOrder: "CONTENT_PORTRAIT_CHANGE_ORDER",
            },
            googleCalendar: {
                landscape: "CONTENT_CALENDAR_MAIN_CREATE",
                left: "CONTENT_CALENDAR_SIDE_CREATE",
                portrait: "CONTENT_CALENDAR_PORTRAIT_CREATE",
            },
            googleSlides: {
                landscape: "CONTENT_SLIDES_MAIN_CREATE",
            },
            powerBI: {
                landscape: "CONTENT_POWERBI_MAIN_CREATE",
            },
            sitePreview: {
                landscape: "CONTENT_SITE_PREVIEW_MAIN_CREATE",
                portrait: "CONTENT_SITE_PREVIEW_PORTRAIT_CREATE",
            },
            fullScreen: {
                list: "CONTENT_FULLSCREEN_VIEW",
                edit: "CONTENT_FULLSCREEN_EDIT",
                changeOrder: "CONTENT_FULLSCREEN_CHANGE_ORDER",
            },
            webpageFeed: {
                create: "CONTENT_FULLSCREEN_CREATE",
            },
        },
    },
    menuPortal: {
        calendar: {
            view: "MENU_CONTENT_VIEW",
            viewDay: "MENU_CONTENT_VIEW",
            selectScreen: "MENU_CONTENT_VIEW",
        },
        menu: {
            create: "MENU_CONTENT_EDIT",
            edit: "MENU_CONTENT_EDIT",
            delete: "MENU_CONTENT_EDIT",
            changeOrder: "MENU_CONTENT_EDIT",
            copy: "MENU_CONTENT_EDIT",
            view: "MENU_CONTENT_VIEW",
        },
    },
    adminPortal: {
        schoolEntities: {
            locations: {
                view: "ADMIN_DISTRICT_VIEW",
                create: "ADMIN_DISTRICT_CREATE",
                edit: "ADMIN_DISTRICT_EDIT",
                delete: "ADMIN_DISTRICT_DELETE",
            },
            buildings: {
                view: "ADMIN_SCHOOL_VIEW",
                create: "ADMIN_SCHOOL_CREATE",
                edit: "ADMIN_SCHOOL_EDIT",
                delete: "ADMIN_SCHOOL_DELETE",
            },
            buildingTypes: {
                view: "ADMIN_SCHOOL_TYPE_VIEW",
                create: "ADMIN_SCHOOL_TYPE_EDIT",
                edit: "ADMIN_SCHOOL_TYPE_EDIT",
                delete: "ADMIN_SCHOOL_TYPE_EDIT",
            },
        },
        businessEntities: {
            locations: {
                view: "ADMIN_BUSINESS_COMPANY_VIEW",
                create: "ADMIN_BUSINESS_COMPANY_CREATE",
                edit: "ADMIN_BUSINESS_COMPANY_EDIT",
                delete: "ADMIN_BUSINESS_COMPANY_DELETE",
            },
            buildings: {
                view: "ADMIN_BUSINESS_BUILDING_VIEW",
                create: "ADMIN_BUSINESS_BUILDING_CREATE",
                edit: "ADMIN_BUSINESS_BUILDING_EDIT",
                delete: "ADMIN_BUSINESS_BUILDING_DELETE",
            },
            buildingTypes: {
                view: "ADMIN_BUSINESS_TYPE_VIEW",
                create: "ADMIN_BUSINESS_TYPE_EDIT",
                edit: "ADMIN_BUSINESS_TYPE_EDIT",
                delete: "ADMIN_BUSINESS_TYPE_EDIT",
            },
        },
        higherEdEntities: {
            locations: {
                view: "ADMIN_HE_DISTRICT_VIEW",
                create: "ADMIN_HE_DISTRICT_CREATE",
                edit: "ADMIN_HE_DISTRICT_EDIT",
                delete: "ADMIN_HE_DISTRICT_DELETE",
            },
            buildings: {
                view: "ADMIN_HE_BUILDING_VIEW",
                create: "ADMIN_HE_BUILDING_CREATE",
                edit: "ADMIN_HE_BUILDING_EDIT",
                delete: "ADMIN_HE_BUILDING_DELETE",
            },
            buildingTypes: {
                view: "ADMIN_HE_BUILDING_TYPE_VIEW",
                create: "ADMIN_HE_BUILDING_TYPE_EDIT",
                edit: "ADMIN_HE_BUILDING_TYPE_EDIT",
                delete: "ADMIN_HE_BUILDING_TYPE_EDIT",
            },
        },
        settings: {
            view: "ADMIN_SETTINGS_VIEW",
            create: "ADMIN_SETTINGS_EDIT",
            edit: "ADMIN_SETTINGS_EDIT",
            delete: "ADMIN_SETTINGS_EDIT",
        },
        builds: {
            view: "ADMIN_BUILDS_EDIT",
            edit: "ADMIN_BUILDS_EDIT",
            create: "ADMIN_BUILDS_EDIT",
            delete: "ADMIN_BUILDS_EDIT",
        },
        buildClaims: {
            view: "ADMIN_BUILD_BIDS_VIEW",
            edit: "ADMIN_BUILD_BIDS_EDIT",
            create: "ADMIN_BUILD_BIDS_EDIT",
            delete: "ADMIN_BUILD_BIDS_EDIT",
        },
        certificates: {
            view: "ADMIN_CERTIFICATE_VIEW",
            edit: "ADMIN_CERTIFICATE_EDIT",
        },
        supportRequests: {
            view: "ADMIN_TICKET_VIEW",
            edit: "ADMIN_TICKET_EDIT",
            delete: "ADMIN_TICKET_DELETE",
        },
        supportRequestTags: {
            view: "ADMIN_TICKET_TAG_VIEW",
            edit: "ADMIN_TICKET_TAG_EDIT",
        },
        deviceModel: {
            view: "ADMIN_DEVICE_MODEL_VIEW",
            edit: "ADMIN_DEVICE_MODEL_EDIT",
        },
    },
    messagesPortal: {
        announcements: {
            list: "MESSAGES_ANNOUNCEMENT_VIEW",
            preview: "MESSAGES_ANNOUNCEMENT_VIEW",
            create: "MESSAGES_ANNOUNCEMENT_EDIT",
            edit: "MESSAGES_ANNOUNCEMENT_EDIT",
            delete: "MESSAGES_ANNOUNCEMENT_EDIT",
        },
        feeds: {
            create: "MESSAGES_LIVE_COMMON_CREATE",

            list: "MESSAGES_LIVE_VIEW",
            preview: "MESSAGES_LIVE_VIEW",
            edit: "MESSAGES_LIVE_EDIT",
            delete: "MESSAGES_LIVE_EDIT",
        },
        streams: {
            create: "MESSAGES_LIVE_RTC_CREATE",

            list: "MESSAGES_LIVE_VIEW",
            preview: "MESSAGES_LIVE_VIEW",
            edit: "MESSAGES_LIVE_EDIT",
            delete: "MESSAGES_LIVE_EDIT",
        },
        alerts: {
            create: "MESSAGES_ALERT_COMMON_CREATE",

            list: "MESSAGES_ALERT_VIEW",
            preview: "MESSAGES_ALERT_VIEW",
            edit: "MESSAGES_ALERT_EDIT",
            delete: "MESSAGES_ALERT_EDIT",
            activation: "MESSAGES_ALERT_MANAGE",
        },
        CAP: {
            activation: "MESSAGES_ALERT_MANAGE",
            edit: "MESSAGES_ALERT_CAP_CREATE",
        },
        breaks: {
            list: "MESSAGES_BREAK_VIEW",
            create: "MESSAGES_BREAK_EDIT",
            edit: "MESSAGES_BREAK_EDIT",
            delete: "MESSAGES_BREAK_EDIT",
        },
        notifications: {
            list: "MESSAGES_NOTIFICATION_VIEW",
            create: "MESSAGES_NOTIFICATION_EDIT",
            delete: "MESSAGES_NOTIFICATION_EDIT",
            useTemplate: "MESSAGES_NOTIFICATION_EDIT",
            useArchive: "MESSAGES_NOTIFICATION_EDIT",
        },
    },
    userPortal: {
        users: {
            system: {
                list: "USERS_SYSTEM_USER_VIEW",
                edit: "USERS_SYSTEM_USER_EDIT",
                delete: "USERS_SYSTEM_USER_EDIT",
                create: "USERS_SYSTEM_USER_EDIT",
                editRole: "USERS_SYSTEM_USER_EDIT_ROLE",
                changeRole: "USERS_SYSTEM_USER_CHANGE_ROLE",
            },
            school: {
                list: "USERS_SCHOOL_USER_VIEW",
                edit: "USERS_SCHOOL_USER_EDIT",
                delete: "USERS_SCHOOL_USER_EDIT",
                create: "USERS_SCHOOL_USER_EDIT",
                editRole: "USERS_SCHOOL_USER_EDIT_ROLE",
                changeRole: "USERS_SCHOOL_USER_CHANGE_ROLE",
            },
            business: {
                list: "USERS_BUSINESS_USER_VIEW",
                edit: "USERS_BUSINESS_USER_EDIT",
                delete: "USERS_BUSINESS_USER_EDIT",
                create: "USERS_BUSINESS_USER_EDIT",
                editRole: "USERS_BUSINESS_USER_EDIT_ROLE",
                changeRole: "USERS_BUSINESS_USER_CHANGE_ROLE",
            },
            student: {
                list: "USERS_STUDENT_USER_VIEW",
                edit: "USERS_STUDENT_USER_EDIT",
                delete: "USERS_STUDENT_USER_EDIT",
                create: "USERS_STUDENT_USER_EDIT",
                editRole: "USERS_STUDENT_USER_EDIT_ROLE",
                changeRole: "USERS_STUDENT_USER_CHANGE_ROLE",
            },
            provider: {
                list: "USERS_PROVIDER_USER_VIEW",
                edit: "USERS_PROVIDER_USER_EDIT",
                delete: "USERS_PROVIDER_USER_EDIT",
                create: "USERS_PROVIDER_USER_EDIT",
                editRole: "USERS_PROVIDER_USER_EDIT_ROLE",
                changeRole: "USERS_PROVIDER_USER_CHANGE_ROLE",
            },
            higherEd: {
                list: "USERS_HIGHER_ED_USER_VIEW",
                edit: "USERS_HIGHER_ED_USER_EDIT",
                delete: "USERS_HIGHER_ED_USER_EDIT",
                create: "USERS_HIGHER_ED_USER_EDIT",
                editRole: "USERS_HIGHER_ED_USER_EDIT_ROLE",
                changeRole: "USERS_HIGHER_ED_USER_CHANGE_ROLE",
            },
            pending: {
                view: "USERS_PENDING_USER_VIEW",
                edit: "USERS_PENDING_USER_EDIT",
            },
        },
        groups: {
            system: {
                list: "USERS_SYSTEM_GROUP_VIEW",
                edit: "USERS_SYSTEM_GROUP_EDIT",
                editRoles: "USERS_SYSTEM_GROUP_EDIT_ROLES",
            },
            school: {
                list: "USERS_SCHOOL_GROUP_VIEW",
                edit: "USERS_SCHOOL_GROUP_EDIT",
                create: "USERS_SCHOOL_GROUP_EDIT",
                delete: "USERS_SCHOOL_GROUP_EDIT",
                editRoles: "USERS_SCHOOL_GROUP_EDIT_ROLES",
            },
            business: {
                list: "USERS_BUSINESS_GROUP_VIEW",
                edit: "USERS_BUSINESS_GROUP_EDIT",
                create: "USERS_BUSINESS_GROUP_EDIT",
                delete: "USERS_BUSINESS_GROUP_EDIT",
                editRoles: "USERS_BUSINESS_GROUP_EDIT_ROLES",
            },
            student: {
                list: "USERS_STUDENT_GROUP_VIEW",
                edit: "USERS_STUDENT_GROUP_EDIT",
                create: "USERS_STUDENT_GROUP_EDIT",
                delete: "USERS_STUDENT_GROUP_EDIT",
                editRoles: "USERS_STUDENT_GROUP_EDIT_ROLES",
            },
            higherEd: {
                list: "USERS_HIGHER_ED_GROUP_VIEW",
                edit: "USERS_HIGHER_ED_GROUP_EDIT",
                create: "USERS_HIGHER_ED_GROUP_EDIT",
                delete: "USERS_HIGHER_ED_GROUP_EDIT",
                editRoles: "USERS_HIGHER_ED_GROUP_EDIT_ROLES",
            },
        },
        roles: {
            view: "USERS_ROLES_VIEW",
            create: "USERS_ROLES_EDIT",
            edit: "USERS_ROLES_EDIT",
            delete: "USERS_ROLES_EDIT",
        },
    },
    studentPortal: {
        local: {
            list: "STUDENT_LOCAL_PROJECT_VIEW",
            create: "STUDENT_LOCAL_PROJECT_EDIT",
            edit: "STUDENT_LOCAL_PROJECT_EDIT",
            delete: "STUDENT_LOCAL_PROJECT_EDIT",
            addContent: "STUDENT_LOCAL_PROJECT_CONTENT_EDIT",
            manage: {
                approve: "STUDENT_LOCAL_PROJECT_CONTENT_MANAGE",
                reject: "STUDENT_LOCAL_PROJECT_CONTENT_MANAGE",
                acceptFor: "STUDENT_LOCAL_PROJECT_CONTENT_MANAGE",
            },
        },
    },
    libraryPortal: {
        canva: "REPOSITORY_CONTENT_CANVA_VIEW",
        canvaContent: "REPOSITORY_CONTENT_CANVA_VIEW",
        premium: {
            libraries: {
                list: "REPOSITORY_PREMIUM_VIEW",
                create: "REPOSITORY_PREMIUM_EDIT",
                view: "REPOSITORY_PREMIUM_VIEW",
                edit: "REPOSITORY_PREMIUM_EDIT",
            },
            content: {
                view: "REPOSITORY_PREMIUM_VIEW",
                edit: "REPOSITORY_PREMIUM_ITEM_EDIT",
            },
            manage: "REPOSITORY_PREMIUM_INFO_EDIT",
        },
        content: {
            local: {
                library: {
                    create: "REPOSITORY_CONTENT_LOCAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_CONTENT_LOCAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_CONTENT_LOCAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_CONTENT_LOCAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_CONTENT_LOCAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_CONTENT_LOCAL_ITEM_EDIT",
                },
            },
            global: {
                library: {
                    create: "REPOSITORY_CONTENT_GLOBAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_CONTENT_GLOBAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_CONTENT_GLOBAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_CONTENT_GLOBAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_CONTENT_GLOBAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_CONTENT_GLOBAL_ITEM_EDIT",
                },
            },
        },
        stickers: {
            local: {
                library: {
                    create: "REPOSITORY_SAMPLE_LOCAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_SAMPLE_LOCAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_SAMPLE_LOCAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_SAMPLE_LOCAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_SAMPLE_LOCAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_SAMPLE_LOCAL_ITEM_EDIT",
                },
            },
            global: {
                library: {
                    create: "REPOSITORY_SAMPLE_GLOBAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_SAMPLE_GLOBAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_SAMPLE_GLOBAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_SAMPLE_GLOBAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_SAMPLE_GLOBAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_SAMPLE_GLOBAL_ITEM_EDIT",
                },
            },
        },
        themes: {
            local: {
                library: {
                    create: "REPOSITORY_THEME_LOCAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_THEME_LOCAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_THEME_LOCAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_THEME_LOCAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_THEME_LOCAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_THEME_LOCAL_ITEM_EDIT",
                },
            },
            global: {
                library: {
                    create: "REPOSITORY_THEME_GLOBAL_REPOSITORY_EDIT",
                    delete: "REPOSITORY_THEME_GLOBAL_REPOSITORY_EDIT",
                    edit: "REPOSITORY_THEME_GLOBAL_REPOSITORY_EDIT",
                    list: "REPOSITORY_THEME_GLOBAL_REPOSITORY_VIEW",
                },
                content: {
                    view: "REPOSITORY_THEME_GLOBAL_REPOSITORY_VIEW",
                    edit: "REPOSITORY_THEME_GLOBAL_ITEM_EDIT",
                },
            },
        },
        brands: {
            list: "REPOSITORY_BRAND_VIEW",
            edit: "REPOSITORY_BRAND_EDIT",
            itemEdit: "REPOSITORY_BRAND_ITEM_EDIT",
            delete: "REPOSITORY_BRAND_ITEM_EDIT",
            create: "REPOSITORY_BRAND_ITEM_EDIT",
        },
    },
    newsPortal: {
        homeNews: {
            list: "NEWS_NEWS_VIEW",
            edit: "NEWS_NEWS_EDIT",
            delete: "NEWS_NEWS_EDIT",
            create: "NEWS_NEWS_EDIT",
            view: "NEWS_NEWS_VIEW",
        },
        helpCenter: {
            list: "NEWS_WIKI_PAGE_VIEW",
            edit: "NEWS_WIKI_PAGE_EDIT",
            delete: "NEWS_WIKI_PAGE_EDIT",
            create: "NEWS_WIKI_PAGE_EDIT",
        },
    },
    homePortal: {
        schoolWidgets: {
            list: "HOME_WIDGETS_SCHOOL",
        },
    },
    reportsPortal: {
        network: {
            view: "REPORTS_NETWORK_VIEW",
        },
        schoolStatistics: {
            view: "REPORTS_SCHOOL_STATISTICS_VIEW",
        },
        studentStatistics: {
            view: "REPORTS_STUDENT_STATISTICS_VIEW",
        },
        changeLog: {
            school: {
                view: "REPORTS_TIMELINE_SCHOOL_VIEW",
            },
            student: {
                view: "REPORTS_TIMELINE_STUDENT_VIEW",
            },
        },
    },
    providerPortal: {
        schoolContent: {
            local: {
                library: {
                    list: "PROVIDER_CONTENT_LOCAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_CONTENT_LOCAL_CONTENT_VIEW",
                    edit: "PROVIDER_CONTENT_LOCAL_CONTENT_EDIT",
                    delete: "PROVIDER_CONTENT_LOCAL_CONTENT_EDIT",
                    create: "PROVIDER_CONTENT_LOCAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_CONTENT_LOCAL_CONTENT_ACCEPT",
                },
            },
            global: {
                library: {
                    list: "PROVIDER_CONTENT_GLOBAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_CONTENT_GLOBAL_CONTENT_VIEW",
                    edit: "PROVIDER_CONTENT_GLOBAL_CONTENT_EDIT",
                    delete: "PROVIDER_CONTENT_GLOBAL_CONTENT_EDIT",
                    create: "PROVIDER_CONTENT_GLOBAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_CONTENT_GLOBAL_CONTENT_ACCEPT",
                },
            },
        },
        schoolThemes: {
            local: {
                library: {
                    list: "PROVIDER_THEME_LOCAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_THEME_LOCAL_CONTENT_VIEW",
                    edit: "PROVIDER_THEME_LOCAL_CONTENT_EDIT",
                    delete: "PROVIDER_THEME_LOCAL_CONTENT_EDIT",
                    create: "PROVIDER_THEME_LOCAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_THEME_LOCAL_CONTENT_ACCEPT",
                },
            },
            global: {
                library: {
                    list: "PROVIDER_THEME_GLOBAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_THEME_GLOBAL_CONTENT_VIEW",
                    edit: "PROVIDER_THEME_GLOBAL_CONTENT_EDIT",
                    delete: "PROVIDER_THEME_GLOBAL_CONTENT_EDIT",
                    create: "PROVIDER_THEME_GLOBAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_THEME_GLOBAL_CONTENT_ACCEPT",
                },
            },
        },
        schoolSamples: {
            local: {
                library: {
                    list: "PROVIDER_SAMPLE_LOCAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_SAMPLE_LOCAL_CONTENT_VIEW",
                    edit: "PROVIDER_SAMPLE_LOCAL_CONTENT_EDIT",
                    delete: "PROVIDER_SAMPLE_LOCAL_CONTENT_EDIT",
                    create: "PROVIDER_SAMPLE_LOCAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_SAMPLE_LOCAL_CONTENT_ACCEPT",
                },
            },
            global: {
                library: {
                    list: "PROVIDER_SAMPLE_GLOBAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_SAMPLE_GLOBAL_CONTENT_VIEW",
                    edit: "PROVIDER_SAMPLE_GLOBAL_CONTENT_EDIT",
                    delete: "PROVIDER_SAMPLE_GLOBAL_CONTENT_EDIT",
                    create: "PROVIDER_SAMPLE_GLOBAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_SAMPLE_GLOBAL_CONTENT_ACCEPT",
                },
            },
        },
        schoolBrands: {
            local: {
                library: {
                    list: "PROVIDER_BRAND_LOCAL_CONTENT_VIEW",
                },
                content: {
                    list: "PROVIDER_BRAND_LOCAL_CONTENT_VIEW",
                    edit: "PROVIDER_BRAND_LOCAL_CONTENT_EDIT",
                    delete: "PROVIDER_BRAND_LOCAL_CONTENT_EDIT",
                    create: "PROVIDER_BRAND_LOCAL_CONTENT_CREATE",
                    changeStatus: "PROVIDER_BRAND_LOCAL_CONTENT_ACCEPT",
                },
            },
        },
        premium: {
            library: {
                list: "PROVIDER_PREMIUM_CONTENT_VIEW",
            },
            content: {
                view: "PROVIDER_PREMIUM_CONTENT_VIEW",
                edit: "PROVIDER_PREMIUM_CONTENT_EDIT",
                create: "PROVIDER_PREMIUM_CONTENT_CREATE",
                changeStatus: "PROVIDER_PREMIUM_CONTENT_ACCEPT",
            },
        },
    },
    settingsPortal: {
        license: {
            view: "SETTINGS_LICENSE_VIEW",
            edit: "SETTINGS_LICENSE_EDIT",
        },
        defaultSettings: {
            systemTv: {
                view: "SETTINGS_DEFAULT_SETTINGS_SYSTEM_VIEW",
                edit: "SETTINGS_DEFAULT_SETTINGS_SYSTEM_EDIT",
            },
            locationTv: {
                view: "SETTINGS_DEFAULT_SETTINGS_LOCATION_VIEW",
                edit: "SETTINGS_DEFAULT_SETTINGS_LOCATION_EDIT",
            },
            buildingTv: {
                view: "SETTINGS_DEFAULT_SETTINGS_BUILDING_VIEW",
                edit: "SETTINGS_DEFAULT_SETTINGS_BUILDING_EDIT",
            },
        },
        integrations: {
            microsoft: {
                view: "SETTINGS_MICROSOFT_SSO_VIEW",
                edit: "SETTINGS_MICROSOFT_SSO_EDIT",
            },
            google: {
                view: "SETTINGS_GOOGLE_SSO_VIEW",
                edit: "SETTINGS_GOOGLE_SSO_EDIT",
            },
        },
    },
}

export interface EditableFlag {
    editable?: boolean
}

export enum Product {
    Scholastic = "SCHOLASTIC",
    Menu = "MENU",
    Athletic = "ATHLETIC",
    Community = "COMMUNITY",
    Classroom = "CLASSROOM",
    HigherEdScholastic = "HE_SCHOLASTIC",
    HigherEdMenu = "HE_MENU",
    HigherEdAthletic = "HE_ATHLETIC",
    HigherEdCommunity = "HE_COMMUNITY",
    HigherEdClassroom = "HE_CLASSROOM",
    Business = "BUSINESS",
    BusinessMenu = "BUSINESS_MENU",
    BusinessClassroom = "BUSINESS_CLASSROOM",

    Lms = "LMS",
    Students = "STUDENTS",
    WebRTC = "WEB_RTC",
    Repositories = "REPOSITORIES",
    Themes = "THEMES",
    Samples = "SAMPLES",
    PremiumLibraries = "PREMIUM_LIBRARIES",
    Trial = "TRIAL",
    Provider = "PROVIDER",
    Canva = "CANVA",
}

export enum ScholasticLicense {
    Campus = "SCHOLASTIC",
    Cafeteria = "MENU",
    Athletic = "ATHLETIC",
    Community = "COMMUNITY",
    Classroom = "CLASSROOM",
    Business = "BUSINESS",
    BusinessMenu = "BUSINESS_MENU",
}

export const getProductsNames = () => ({
    // K-12 (School)
    [Product.Scholastic]: i18n.t("glossary:product.campus_school"),
    [Product.Menu]: i18n.t("glossary:product.cafeteria_school"),
    [Product.Athletic]: i18n.t("glossary:product.athletic_school"),
    [Product.Community]: i18n.t("glossary:product.community_school"),
    [Product.Classroom]: i18n.t("glossary:product.classroom_school"),

    // Business
    [Product.Business]: i18n.t("glossary:product.business"),
    [Product.BusinessMenu]: i18n.t("glossary:product.cafeteria_business"),
    [Product.BusinessClassroom]: i18n.t("glossary:product.workspace"),

    // Higher ed
    [Product.HigherEdScholastic]: i18n.t("glossary:product.campus_higher_ed"),
    [Product.HigherEdMenu]: i18n.t("glossary:product.cafeteria_higher_ed"),
    [Product.HigherEdAthletic]: i18n.t("glossary:product.athletic_higher_ed"),
    [Product.HigherEdCommunity]: i18n.t("glossary:product.community_higher_ed"),
    [Product.HigherEdClassroom]: i18n.t("glossary:product.classroom_higher_ed"),

    // Common?
    [Product.Lms]: i18n.t("glossary:common_products.virtual_connect"),
    [Product.Students]: i18n.t("glossary:common_products.student_projects"),
    [Product.WebRTC]: i18n.t("glossary:common_products.web_rtc"),
    [Product.Repositories]: i18n.t("glossary:common_products.content_libraries"),
    [Product.Themes]: i18n.t("glossary:common_products.theme_collections"),
    [Product.Samples]: i18n.t("glossary:common_products.stickers"),
    [Product.Trial]: i18n.t("glossary:common_products.trial_connect"),
    [Product.Provider]: i18n.t("glossary:common_products.providers"),
    [Product.PremiumLibraries]: i18n.t("glossary:common_products.premium_libraries"),
    [Product.Canva]: i18n.t("glossary:common_products.canva"),
})

export const PortalsNames: {[p in Portal]: string} = {
    [Portal.Admin]: "Admin",
    [Portal.Auth]: "Auth",
    [Portal.Content]: "Content",
    [Portal.Creative]: "Creative",
    [Portal.Home]: "Home",
    [Portal.Menu]: "Menu",
    [Portal.Messages]: "Message",
    [Portal.Repository]: "Library",
    [Portal.Screens]: "Screen",
    [Portal.Student]: "Student",
    [Portal.Users]: "User",
    [Portal.News]: "News",
    [Portal.Reports]: "Reports",
    [Portal.Provider]: "Provider",
    [Portal.Settings]: "Settings",
    [Portal.WebPlayer]: "Web player",
}

export const getPortalsPanelData = (): {
    [p in PortalsPanelItem | "CREATIVE"]: {name: string; description: string}
} => ({
    [PortalsPanelItem.Home]: {
        name: i18n.t("common:portals_grid.home"),
        description: i18n.t("home_portal:home_page.portal_description.home"),
    },
    ["CREATIVE"]: {
        name: i18n.t("common:portals_grid.creative"),
        description: "",
    },
    [PortalsPanelItem.Users]: {
        name: i18n.t("common:portals_grid.user"),
        description: i18n.t("home_portal:home_page.portal_description.user"),
    },
    [PortalsPanelItem.Admin]: {
        name: i18n.t("common:portals_grid.admin"),
        description: i18n.t("home_portal:home_page.portal_description.admin"),
    },
    [PortalsPanelItem.Screens]: {
        name: i18n.t("common:portals_grid.screen"),
        description: i18n.t("home_portal:home_page.portal_description.screen"),
    },
    [PortalsPanelItem.Content]: {
        name: i18n.t("common:portals_grid.content"),
        description: i18n.t("home_portal:home_page.portal_description.content"),
    },
    [PortalsPanelItem.Repository]: {
        name: i18n.t("common:portals_grid.library"),
        description: i18n.t("home_portal:home_page.portal_description.library"),
    },
    [PortalsPanelItem.Student]: {
        name: i18n.t("common:portals_grid.student"),
        description: i18n.t("home_portal:home_page.portal_description.student"),
    },
    [PortalsPanelItem.Messages]: {
        name: i18n.t("common:portals_grid.message"),
        description: i18n.t("home_portal:home_page.portal_description.message"),
    },
    [PortalsPanelItem.Menu]: {
        name: i18n.t("common:portals_grid.menu"),
        description: i18n.t("home_portal:home_page.portal_description.menu"),
    },
    [PortalsPanelItem.News]: {
        name: i18n.t("common:portals_grid.news"),
        description: i18n.t("home_portal:home_page.portal_description.news"),
    },
    [PortalsPanelItem.Reports]: {
        name: i18n.t("common:portals_grid.reports"),
        description: i18n.t("home_portal:home_page.portal_description.reports"),
    },
    [PortalsPanelItem.Provider]: {
        name: i18n.t("common:portals_grid.provider"),
        description: i18n.t("home_portal:home_page.portal_description.provider"),
    },
    [PortalsPanelItem.Settings]: {
        name: i18n.t("common:portals_grid.settings"),
        description: i18n.t("home_portal:home_page.portal_description.settings"),
    },
    [PortalsPanelItem.Alerts]: {
        name: i18n.t("common:portals_grid.alerts"),
        description: i18n.t("home_portal:home_page.portal_description.alerts"),
    },
})
