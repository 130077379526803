import React, {ReactNode, FC, useContext} from "react"

export const ModalLoaderContext = React.createContext<HTMLDivElement | null>(null)

export const ModalPositionContext = React.createContext<{id: string; right: boolean} | null>(null)
export const useModalPosition = () => useContext(ModalPositionContext)

export const ModalScrollViewContext = React.createContext<HTMLDivElement | null>(null)

export const ModalTopStickyContext = React.createContext<FC<{children: ReactNode}> | null>(null)
export const useModalTopStickyContext = () => useContext(ModalTopStickyContext)
