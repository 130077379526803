import React, {useEffect, useRef, useState} from "react"
import {SystemNotification} from "../../SocketWrapper/Notifications/useNotificationsListener"
import styles from "./styles.module.scss"
import {BodyPortal} from "../../BodyPortal"
import classNames from "classnames"
import {useNotificationViewMethodProvider} from "../../SocketWrapper/Notifications/hooks"
import {useNotificationsTimeThreshold} from "../../../hooks/useNotificationsTimeThreshold"
import {Icon, NameIcon} from "../../../ui/Icon/Icon"
import {Box} from "../../../ui/Box/Box"
import {Text} from "../../../ui/Text/Text"
import {Button} from "../../../ui/Button/Button"
import {SmoothAnimations, SmoothComponent} from "../../../ui/SmoothComponent/SmoothComponent"
import {useTranslation} from "react-i18next"
import {ReactComponent as InfoIcon} from "./info.svg"

export const NotificationsDropdown: React.FC<{
    hover: boolean
    active: boolean
    notifications: Array<SystemNotification>
    catchEyeNotification?: SystemNotification
    clearCatchEyeNotification: (viewedId?: number) => void
    useDispatch: any
    forcedIconColor?: string
}> = ({
    active,
    hover,
    notifications,
    catchEyeNotification,
    clearCatchEyeNotification,
    useDispatch,
    forcedIconColor,
}) => {
    const {t} = useTranslation(["common"])
    const containsNotViewed = notifications.some(({viewed}) => !viewed)

    const [isViewedCatchEye, setIsViewed] = useState(false)
    const [catchEye, setCatchEye] = useState<SystemNotification>()
    const timeoutRef = useRef<number>()

    useEffect(() => {
        clearTimeout(timeoutRef.current)
        if (!active) {
            if (catchEyeNotification) {
                setCatchEye(catchEyeNotification)
                const timer1 = Date.now()
                window.requestAnimationFrame(() => {
                    const timer2 = Date.now()
                    //normally, delta is about 20-50ms
                    const isUserWasInactive = timer2 - timer1 > 5000
                    timeoutRef.current = Number(
                        setTimeout(
                            () => {
                                clearCatchEyeNotification()
                            },
                            isUserWasInactive ? 8000 : 4500
                        )
                    )
                })
            }
        } else {
            clearCatchEyeNotification()
        }
    }, [catchEyeNotification, active, clearCatchEyeNotification])

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [])

    const normalizedLink = catchEye?.link?.startsWith("http")
        ? catchEye?.link
        : `http://${catchEye?.link}`

    const {view} = useNotificationViewMethodProvider(useDispatch)

    const {setValue} = useNotificationsTimeThreshold()
    const viewNotification = () => {
        view([catchEyeNotification!.id])
        if (catchEyeNotification?.created) setValue(catchEyeNotification?.created)
        setIsViewed(true)
    }

    return (
        <>
            <div
                className={classNames(styles.iconWrapper, {[styles.notViewed]: containsNotViewed})}
            >
                <Icon
                    name="notification"
                    style={{
                        stroke: forcedIconColor
                            ? forcedIconColor
                            : active || hover
                            ? "#FFF"
                            : "#96B7CA",
                    }}
                />
            </div>
            {!active && (
                <BodyPortal>
                    <SmoothComponent
                        show={!!catchEyeNotification}
                        animation={SmoothAnimations.TO_LEFT}
                    >
                        <div
                            className={classNames(styles.previewCatchEye, {
                                [styles.viewed]: isViewedCatchEye,
                            })}
                            onMouseEnter={() => {
                                clearTimeout(timeoutRef.current)
                                timeoutRef.current = Number(
                                    setTimeout(() => {
                                        clearCatchEyeNotification()
                                    }, 10000)
                                )
                            }}
                            onClick={(event) => event.stopPropagation()}
                        >
                            <div className={styles.iconBox}>
                                {catchEye?.isEvent || catchEye?.messageType === "SUPPORT" ? (
                                    <InfoIcon />
                                ) : (
                                    <Icon
                                        name={catchEye?.messageType as NameIcon}
                                        className={styles.icon}
                                    />
                                )}
                                <div className={styles.notViewed} />
                            </div>
                            <div className={styles.textAndLink}>
                                <div className={styles.text}>{catchEye?.text}</div>
                                <Box flex={{justifyContent: "space-between"}} mt={0.5}>
                                    {catchEye?.linkText && catchEye?.link ? (
                                        <a
                                            target={"_blank"}
                                            href={normalizedLink}
                                            rel={"noreferrer"}
                                            onClick={viewNotification}
                                        >
                                            {catchEye?.linkText}
                                        </a>
                                    ) : (
                                        <div />
                                    )}
                                    <Button
                                        text
                                        onClick={() => {
                                            clearCatchEyeNotification(catchEyeNotification?.id)
                                        }}
                                    >
                                        <Text color="warm-grey">{t`common:ui.skip`}</Text>
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </SmoothComponent>
                </BodyPortal>
            )}
        </>
    )
}
