import React, {useContext, useMemo, useState} from "react"
import {MobileContext} from "scholastic-client-components/src/context/mobileContext"
import {Authorities} from "scholastic-client-components/src/types/securityTypes"
import {Box} from "scholastic-client-components/src/ui/Box/Box"
import {BlackBox, EducateBox} from "./Boxes"
import {useAdaptiveLimitedWidth} from "./hooks/useAdaptiveLimitedWidth"
import {Text} from "scholastic-client-components/src/ui/Text/Text"
import {ReactComponent as Arrow} from "./arrow.svg"
import {Dropdown} from "scholastic-client-components/src/ui/Dropdown/Dropdown"
import {Location} from "../../../../../api/homeAPI"
import {LocationItem} from "./LocationItem"
import styles from "./NetworkBlocks.module.scss"
import {shownLocationsCount} from "../../../../../utilities/helpers"
import {AdjustableScrollbars} from "scholastic-client-components/src/ui/Scrollbars"
import {useTranslation} from "react-i18next"
import {i18n} from "scholastic-client-components/src/i18n/i18n"

const reNameAdmin = (name: string) => {
    switch (name) {
        case Authorities.admins.master:
            return i18n.t("person:role.master_admin")
        case Authorities.admins.support:
            return i18n.t("person:role.support_admin")
    }
}

export const AdminGreeting: React.FC<{adminAuthority: string}> = ({adminAuthority}) => {
    return (
        <Box mt={0.8125} height={2.125} flex={{alignItems: "stretch"}}>
            <BlackBox padSide={1.1875}>{reNameAdmin(adminAuthority)}</BlackBox>
        </Box>
    )
}

interface LocationProps {
    locationsList: Location[]
    totalBuildings: number
}

const firstLocationWithoutBuilding = 1
const firstLocationWithBuilding = 2

export const LocationInfo: React.FC<LocationProps> = ({locationsList, totalBuildings}) => {
    const {t} = useTranslation(["home_portal"])
    const [open, setOpen] = useState(false)
    const mobileContext = useContext(MobileContext)
    const limitedWidth = useAdaptiveLimitedWidth(mobileContext)

    const firstLocationInfo = locationsList[0]
    const firstBuildingInfo = firstLocationInfo.buildings[0]

    const restLocationsList = useMemo(() => {
        const list = [...locationsList.map((item) => ({...item, buildings: [...item.buildings]}))]

        const firstLocation = list[0]

        if (firstLocation.buildings.length > 1) {
            firstLocation.buildings.shift()
        } else {
            list.shift()
        }

        return list
    }, [locationsList])

    const restTotalBuildings =
        firstLocationInfo.locationLevelUser && firstBuildingInfo
            ? totalBuildings - firstLocationWithBuilding
            : totalBuildings - firstLocationWithoutBuilding

    const hiddenLocationsCount = restTotalBuildings - shownLocationsCount

    return (
        <>
            <Box propsStyles={{flexWrap: "wrap"}} flex={{justifyContent: "center"}}>
                {firstLocationInfo.name && (
                    <Box flex mt={0.5625} mr={firstBuildingInfo ? 1 : 0}>
                        <EducateBox
                            limitedWidth={limitedWidth}
                            text={firstLocationInfo.name}
                            noWrap
                            location
                        />
                    </Box>
                )}
                {firstBuildingInfo?.name && (
                    <Box flex mt={0.5625}>
                        <EducateBox
                            limitedWidth={limitedWidth}
                            text={firstBuildingInfo.name}
                            noWrap
                            building
                        />
                    </Box>
                )}
            </Box>
            {restTotalBuildings > 0 && (
                <Dropdown
                    open={open}
                    setOpen={setOpen}
                    position={"bottom center"}
                    dropdownClass={styles.dropdown}
                    trigger={
                        <Box mt={0.8125} height={1.6875} flex={{alignItems: "stretch"}}>
                            <BlackBox ml={0.625} padSide={0.625} darkBackground={open}>
                                <Text hex={"#D4D4D4"} medium>
                                    +{" "}
                                    {t("home_portal:home_page.person_info.more_location", {
                                        count: restTotalBuildings,
                                    })}
                                </Text>
                                <Arrow style={{marginLeft: "8px"}} />
                            </BlackBox>
                        </Box>
                    }
                >
                    <AdjustableScrollbars
                        autoHeightMax={246}
                        renderThumbVertical={(props) => <div {...props} className={styles.thumb} />}
                    >
                        <div className={styles.content}>
                            {restLocationsList.map((location) => (
                                <LocationItem
                                    key={location.id}
                                    location={location}
                                    limitedWidth={limitedWidth}
                                />
                            ))}
                            {hiddenLocationsCount > 0 && (
                                <div className={styles.moreItems}>
                                    +{" "}
                                    {t("home_portal:home_page.person_info.hidden_locations_count", {
                                        hidden_count: hiddenLocationsCount,
                                    })}
                                </div>
                            )}
                        </div>
                    </AdjustableScrollbars>
                </Dropdown>
            )}
        </>
    )
}
