import classNames from "classnames"
import React from "react"
import {HeaderAlert} from "../../SocketWrapper/Alerts/useAlertListener"
import styles from "./styles.module.scss"
import NoPreviewLogo from "./graphics/NoPreviewLogo.svg"

export const AlertDropdown: React.FC<{
    alerts: Array<HeaderAlert>
    tallerHeader?: boolean
}> = ({alerts, tallerHeader}) => {
    const firstAlert = alerts[0]
    return (
        <div className={classNames(styles.alertIcon, {[styles.isHomePortal]: tallerHeader})}>
            <div
                className={classNames([styles.preview, styles.inHeaderPreview])}
                style={{backgroundImage: `url(${firstAlert.preview?.thumbnail || NoPreviewLogo})`}}
            />
            <div className={styles.headerRightBlock}>
                <span className={styles.headerTitle}>
                    {alerts.length > 1 ? `${alerts.length} alerts enabled` : firstAlert.name}
                </span>
                <div className={styles.dropdown} />
            </div>
        </div>
    )
}
